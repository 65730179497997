<template>
  <div >
    <!-- <element-view></element-view> -->
     <!-- 员工管理组件 -->
     <!-- <emp-view></emp-view> -->
      <router-view></router-view>

  </div>
</template>

<script>
// import ElementView from "./views/element/ElementView.vue"
// import EmpView from "./views/adminsystem/EmpView.vue"
export default {
  components: {
    // ElementView
    //EmpView
  },

  data() {
    return {
      message: "hello vue"
    }
  }

}

</script>

<style>

</style>
