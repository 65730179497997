import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
//前端页面路由表
const routes = [
  {
    path: '/',
    redirect: '/emp'  /* 默认一打开访问这个路径 */ 
  },
  {
    path: '/dept',
    name: 'dept',
  
    component: () => import('../views/adminsystem/DeptView.vue')
  },
  {
    path: '/emp',
    name: 'emp',
    component: () => import('../views/adminsystem/EmpView.vue')  /* webpackChunkName: "about" */ 
  },
]

const router = new VueRouter({
  routes
})

export default router
